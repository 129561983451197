<script setup lang="ts">
import { useCompanyStore } from "@/store/company";

interface Props {
  claimed: boolean;
}

interface Emits {
  (e: "claimed"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const isLoading = ref(false);
const companyStore = useCompanyStore();
const { company } = storeToRefs(companyStore);

const claimFreeCredit = () => {
  if (company.value!.promo_claimed || props.claimed) return showNotifyError(ERROR_MESSAGES.promo_already_claimed);

  companyStore.claimFreeCredit();

  isLoading.value = true;
  setTimeout(() => {
    isLoading.value = false;
    fireDefaultConfetti();
    emit("claimed");
  }, 2000);
};
</script>

<template>
  <div class="w-full h-full relative">
    <BaseImage src="/employers/purchase-job-credits.png" alt="Purchase Credits" sizes="720px" class="w-full rounded-lg object-cover" />
    <!-- FREE CREDIT -->
    <div v-if="!company?.promo_claimed" class="absolute flex items-center justify-center inset-0 z-10 h-full">
      <div class="relative bg-white rounded-full">
        <transition name="slide-fade" mode="out-in">
          <PrimaryButton
            v-if="!claimed"
            secondary
            large
            @click="claimFreeCredit"
            :disabled="isLoading"
            :spinner="isLoading"
            class="relative z-10 shadow-[0_0_15px_5px_rgba(0,0,0,0.3),0_0_30px_15px_rgba(0,0,0,0.2),0_0_45px_25px_rgba(0,0,0,0.1)]"
          >
            Claim Free Credit 🎁
          </PrimaryButton>
          <PrimaryButton
            v-else
            secondary
            large
            class="relative z-10 shadow-[0_0_15px_5px_rgba(0,0,0,0.3),0_0_30px_15px_rgba(0,0,0,0.2),0_0_45px_25px_rgba(0,0,0,0.1)]"
          >
            Claimed! 🎉</PrimaryButton
          >
        </transition>
      </div>
    </div>
    <!-- FREE CREDIT END -->
  </div>
</template>
